import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col } from "react-bootstrap"
import SEO from 'components/seo'
import ContactForm from "../components/Contact"
const ContactTemplate = ({
  data, location // this prop will be injected by the GraphQL query below.
}) => {
  const { mdx } = data // data.mdx holds your post data
  const { frontmatter, body, excerpt } = mdx
  return (
    <>
      <SEO title={frontmatter.title} description={excerpt}/>
        <Container fluid className="container">
          <Row className="justify-content-md-center section-section">
            <Col md="6">
            <MDXRenderer className="pages-content">
              {body}
            </MDXRenderer>
            </Col>   
            <Col md="6">
              <ContactForm location={location} success={frontmatter.success} error={frontmatter.error} />
            </Col>
          </Row>
        </Container>
    </>
  )
};

export default ContactTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        success
        error
      }
    }
  }
`